import {Collapse, TableContainer, TableHead, Theme, Tooltip} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {createStyles, makeStyles} from '@mui/styles'
import Table from "@mui/material/Table";
import ReportTableHeaderRow from "./ReportTableHeaderRow";
import Paper from "@mui/material/Paper";
import ReportTableBody from "./ReportTableBody";
import Grid from "@mui/material/Grid";
import TablePagination from "@mui/material/TablePagination";
import FileDownloadIcon from '@mui/icons-material/CloudDownload';
import IconButton from "@mui/material/IconButton";
import ExportTableDialog from "../ExportTableDialog";
import FieldFeaturedFilter from "../report/filters/FieldFeaturedFilter";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import {FilterBuilder} from "hashtagjeff-shared-components";
import LeftRightAlign from "../LeftRightAlign";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import HiddenColumnSelector from "../report/HiddenColumnSelector";
import {InfoOutlined} from "@mui/icons-material";
import {addMinutes, format, fromUnixTime} from "date-fns";
import {Row, useReportTable} from "./useReportTable";
import {FeaturedFilterInputValue} from "./useTableFilters";
import TableType from "../../types/Table";
import ColumnFieldOptionSelect from "./ColumnFieldOptionSelect";
import Column from "../../types/Column";
import {Report} from "../../types/Report";
import {DataSource} from "../../types/DataSource";
import {Field} from "../../types/Field";
import isEqual from "react-fast-compare";
import useEffectWhen from "../../util/useEffectWhen";
import {isSearchConsoleConfigured} from "../../util/isSearchConsoleConfigured";
import isSearchConsoleEnabled from "../../util/isSearchConsoleEnabled";
import ReportTableContext, {ReportTableContextType} from "../../context/ReportTableContext";
import PostDetailsPanel from "../post-details-panel/PostDetailsPanel";
import DisabledReportTableMessage from "./DisabledReportTableMessage";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {tableInputSelector, updateTableInput} from "../../store/slice/filtersSlice";
import ReportTableMutiselectActions from "./ReportTableMultiselectActions";

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {
    }
}

type ReportStylesProps = {
    maxHeight: string
}

const useReportStyles = makeStyles<Theme, ReportStylesProps>((theme: Theme) => createStyles({
    root: {
        maxHeight: props => props.maxHeight,
        display: "block"
    },
    advancedFiltersPopover: {
        padding: theme.spacing(2),
        width: "650px"
    },
    tableInfoIcon: {
        marginRight: "20px"
    },
    tableWrap: {
        height: "600px",
        overflow: "auto",
        position: "relative"
    }
}));

const getDefaultColumnFieldOptionInput = (table: TableType): SelectedColumnFieldOptions => {
    return table.columns
        .filter(column => column.use_multiple_fields)
        .map(({id, user_column_field_options}) =>
            [id, user_column_field_options[0].field_id]
        )
        .reduce((result, [key, value]) => {
            result[key] = value;
            return result;
        }, {});
}

export type SelectedColumnFieldOptions = {
    [columnId: number]: number
}

export type ReportTableProps = {
    tableData: TableType,
    maxHeight?: string,
    dataSource: DataSource,
    report: Report,
    label: string | undefined
}

const shouldColumnAppearInVisibilitySelector = (column: Column) => column.visibility === 'hidden' || (column.formatting_options.allow_hiding === true)


const filterFieldsForAdvancedFilters = (fields: Field[], table: TableType) => {
    return fields
        .filter(field => field.allow_filtering)
        .filter(field => table.inherit_all_advanced_filter_fields || (table.advanced_filter_fields.includes(field.id)))
}

const getCollectionDate = collectionDate => {
    const date = fromUnixTime(parseInt(collectionDate));
    return <Typography>{"Data collection date: " + format(addMinutes(date, date.getTimezoneOffset()), "LLL d, yyyy")}</Typography>;
}

const ReportTable = ({tableData, maxHeight = "600px", dataSource, report, label}: ReportTableProps) => {
    const ref = useRef();
    const [error, setError] = useState<string | null>(null);
    const [rankingPanelPage, setRankingPanelPage] = useState<{
        url: string;
        modified: number;
        published: number;
    } | undefined>(undefined);
    const [selectedUrls, setSelectedUrls] = useState<readonly string[]>([]);
    const [rankingsPanelOpen, setRankingsPanelOpen] = useState(false);
    const {
        isLoading,
        pageOptions,
        tableFeaturedFilters,
        updateFeaturedFilterInput,
        featuredFilterInput,
        activeTablePresetId,
        setActiveTablePresetId,
        userAdvancedFilters: appliedAdvancedFilters,
        setAdvancedFilters: updateAppliedAdvancedFilters,
        sortBy,
        setSortBy,
        tableContentsResponse,
        setPageOptions,
        useTableExport,
        defaultFeaturedFilterInput,
        useFilterHash,
        lastKnownRowCount,
        getFeaturedFilterCountParams,
        useGetAllRows,
    } = useReportTable(tableData, dataSource, report.id, setError, () => {}, selectedUrls.length,  ref);

    const [isExportDialogOpen, setIsExportDialogOpen] = useState(false);
    const [advancedFilterData, setAdvancedFilterData] = useState([]); // User input before it's finalized
    const [advancedFiltersButtonRef, setAdvancedFiltersButtonRef] = useState(null);
    const dispatch = useAppDispatch();
    const {enabledHiddenColumns} = useAppSelector(tableInputSelector(tableData.id));
    const setEnabledHiddenColumns = (newEnabledHiddenColumns: number[]) => {
        dispatch(updateTableInput({
            tableId: tableData.id,
            input: {
                enabledHiddenColumns: newEnabledHiddenColumns
            }
        }))
    }

    const [selectedColumnFieldOptions, setSelectedColumnFieldOptions] = useState<SelectedColumnFieldOptions>(
        () => getDefaultColumnFieldOptionInput(tableData)
    );

    useEffectWhen(() => {
        if (!isEqual(advancedFilterData, appliedAdvancedFilters)) {
            setAdvancedFilterData(appliedAdvancedFilters);
        }
    }, [advancedFilterData], [appliedAdvancedFilters]);

    const isAConnectedColumn = (columnId: number): boolean => {
        let currentColumn = tableData.columns.find(col => col.id === columnId);
        return currentColumn.multiple_fields_select_label != null &&
               tableData.columns.some(col => col.multiple_fields_select_label === currentColumn.multiple_fields_select_label);
    }

    const updateConnectedColumns = (columnId: number, selectedFieldId: number): void => {
        let currentColumn = tableData.columns.find(col => col.id === columnId);
        let currentColumnLabel = currentColumn.user_column_field_options.find(option => option.field_id === selectedFieldId).label;
        let updates = tableData.columns
            .filter(col => col.multiple_fields_select_label === currentColumn.multiple_fields_select_label)
            .map(col => col.user_column_field_options)
            .flat()
            .reduce((result, option) => {
                if(option.label === currentColumnLabel){
                    result[option.column_id] = option.field_id;
                }
                return result;}, {})
            setSelectedColumnFieldOptions({
                ...selectedColumnFieldOptions,
                ...updates
            });
    }

    const updateSelectedColumnField = (columnId: number, selectedFieldId: number) => {
        if(isAConnectedColumn(columnId)) {
            updateConnectedColumns(columnId, selectedFieldId);
        }
        else {
            setSelectedColumnFieldOptions({
                ...selectedColumnFieldOptions,
                [columnId]: selectedFieldId
            })
        }
    }

    const classes = useReportStyles({maxHeight});

    const displayedColumns = tableData.columns
        .filter(column =>
            (!shouldColumnAppearInVisibilitySelector(column) && column.visibility === "visible") || enabledHiddenColumns.includes(column.id)
        )
        .sort((a, b) => a.position - b.position);
    const totalWidths = displayedColumns.map(column => column.formatting_options?.width || 1).reduce((a, b) => a + b);

    const onRankingsClick = (row: Row) => {
        const urlFieldValue = row["field_" + tableData.url_field] as string;
        const datePublished = parseInt(row["field_" + tableData.search_console_settings.date_published_field_id] as string) * 1000
        const dateModified = parseInt(row["field_" + tableData.search_console_settings.date_modified_field_id] as string) * 1000;
        setRankingPanelPage({
            url: urlFieldValue,
            published: datePublished,
            modified: dateModified
        });
        setRankingsPanelOpen(true);
    }

    const getUrlFromRow = (row : Row) => {return row["field_" + tableData.url_field] as string}
    const allSelected = !isLoading && tableContentsResponse?.rows.every(row => selectedUrls.indexOf(getUrlFromRow(row)) !== -1);
    const noneSelected = selectedUrls.length === 0;
    const someSelected = !allSelected && !noneSelected;

    const clearRowSelctions = () => {setSelectedUrls([]);}
    useEffect(() => setSelectedUrls([]),[tableContentsResponse]);
    const selectAllRows = () => {
        if(isLoading) {return;}
        let newSelected: readonly string[];
        if(allSelected) {
            newSelected = [];
        } else if(noneSelected){
            newSelected = tableContentsResponse?.rows.map(getUrlFromRow);
        } else {
            newSelected = [...selectedUrls, ...tableContentsResponse?.rows.filter(row => selectedUrls.indexOf(getUrlFromRow(row)) === -1).map(getUrlFromRow)];
        }
        console.log(newSelected);
        setSelectedUrls(newSelected);
    }

    const useSelectAllAcrossPages = async () => {
        let response = await useGetAllRows();
        setSelectedUrls(response.rows.map(getUrlFromRow));
    };

    const onRowSelect = (row : Row) => {
        if(isLoading) {return;}
        let newSelected: readonly string[];
        if(selectedUrls.indexOf(getUrlFromRow(row)) === -1) {
            newSelected = [...selectedUrls, getUrlFromRow(row)];
        } else {
            newSelected = selectedUrls.filter((r) => r !== getUrlFromRow(row));
        }
        setSelectedUrls(newSelected);
        console.log(`# rows selected: %d`, newSelected.length);
        console.log(newSelected);
    }

    const reportTableContextValue: ReportTableContextType = {
        tableId: tableData.id,
        featuredFilterInput,
        getFeaturedFilterCountParams,
        useFilterHash
    }

    const searchConsoleEnabled = (isSearchConsoleConfigured(report) && isSearchConsoleEnabled(tableData));
    const showActionsCell = tableData.url_field !== null && (searchConsoleEnabled || report.enable_page_trackers || report.enable_notes);

    return <ReportTableContext.Provider value={reportTableContextValue}>
        <Paper ref={ref} elevation={1} sx={{pt: 1}}>
            {label ? <Typography variant={"h3"} sx={{m: 2}}>{label}</Typography> : null}
            <Collapse in={isLoading} data-test={'table-progress-bar'}>
                <LinearProgress/>
            </Collapse>
            <Box p={2}>
                <LeftRightAlign
                    left={<Box>
                        {tableFeaturedFilters.map(featuredFilter => (
                            <FieldFeaturedFilter
                                key={featuredFilter.id}
                                onChange={input => updateFeaturedFilterInput(featuredFilter.id, input)}
                                value={featuredFilterInput.find(input => input.featuredFilterId === featuredFilter.id).input as FeaturedFilterInputValue & string}
                                featuredFilter={featuredFilter}
                                sx={{mr: 1, mt: 1}}
                                size={"small"}
                                defaultFilterInput={defaultFeaturedFilterInput.find(ffInput => ffInput.featuredFilterId === featuredFilter.id)}
                                disabled={tableData.disabled}
                            />
                        ))}
                        {!tableData.disable_advanced_filters ?
                            <Button
                                size={"small"}
                                sx={{mt: 1}}
                                color={"secondary"}
                                onClick={event => setAdvancedFiltersButtonRef(event.target)}
                                disabled={tableData.disabled}
                            >
                                Advanced...
                            </Button> : null}
                    </Box>}
                    right={<>
                        {tableData.filter_presets.length > 0 ?
                            <TextField
                                select
                                value={activeTablePresetId !== null ? activeTablePresetId : "none"}
                                onChange={event => setActiveTablePresetId(event.target.value !== "none" ? parseInt(event.target.value) : null)}
                                label={"Filter Preset"}
                                sx={{minWidth: "150px"}}
                            >
                                <MenuItem value={"none"}>None</MenuItem>
                                {tableData.filter_presets.map(fp => (
                                    <MenuItem key={fp.id} value={fp.id}>{fp.label}</MenuItem>
                                ))}
                            </TextField> : null}
                        <HiddenColumnSelector
                            columns={tableData.columns.filter(shouldColumnAppearInVisibilitySelector)}
                            onChange={setEnabledHiddenColumns}
                            displayedColumnIds={enabledHiddenColumns}
                            disabled={tableData.disabled}
                        />
                    </>}
                />
                <Popover
                    open={Boolean(advancedFiltersButtonRef)}
                    onClose={() => setAdvancedFiltersButtonRef(null)}
                    anchorEl={advancedFiltersButtonRef}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={classes.advancedFiltersPopover}>
                        <FilterBuilder
                            value={advancedFilterData}
                            onChange={setAdvancedFilterData}
                            fields={filterFieldsForAdvancedFilters(dataSource.fields, tableData)}
                            disabledCompareTypes={{
                                "date": ["greater_than_relative", "less_than_relative"]
                            }}
                        />
                        <LeftRightAlign
                            right={<Button onClick={() => {
                                updateAppliedAdvancedFilters(advancedFilterData);
                                setAdvancedFiltersButtonRef(null);
                            }}>Apply</Button>}
                        />
                    </div>
                </Popover>
                {tableData.columns.filter(({use_multiple_fields}) => use_multiple_fields)
                                  .reduce((result, column) => {
                                    if(!result.some(col => col.multiple_fields_select_label === column.multiple_fields_select_label)) {
                                        result = [...result, column]
                                    }
                                    return result;
                                  }, [])
                                  .map(column => (
                    <ColumnFieldOptionSelect
                        key={column.id}
                        column={column}
                        value={selectedColumnFieldOptions[column.id]}
                        onChange={(fieldId) => updateSelectedColumnField(column.id, fieldId)}
                        sx={{mt: 3, width: "180px"}}
                        disabled={tableData.disabled}
                    />
                ))}
            </Box>
            {tableData.disabled ? null :
            <Collapse in={!noneSelected} data-test={'multiselect-buttons'}>
                <ReportTableMutiselectActions
                    urls={selectedUrls}
                    totalRows={lastKnownRowCount}
                    selectAllAcrossPages={useSelectAllAcrossPages}
                    sx={{mr: 1, mt: 1}}
                    size={"small"}
                    clearSelection={clearRowSelctions}/>
            </Collapse>
            }
            <div className={classes.tableWrap}>
                {tableData.disabled ? <DisabledReportTableMessage table={tableData}/> :
                    (<Table style={{tableLayout: 'fixed'}} stickyHeader>
                        <colgroup>
                            {showActionsCell ? <col/> : null}
                            {displayedColumns.map(column => (
                                <col
                                    style={{width: ((column.formatting_options?.width || 1) / totalWidths * 100) + "%"}}
                                    key={column.id}
                                />
                            ))}

                            {showActionsCell ? <col style={{width: (1 / totalWidths * 100) + "%"}}/> : null}
                        </colgroup>
                        <TableHead>
                            <ReportTableHeaderRow
                                displayColumns={displayedColumns}
                                sortByData={sortBy}
                                updateSortByData={setSortBy}
                                selectAllRows={selectAllRows}
                                isIndeterminate={someSelected}
                                isChecked={allSelected}
                                fields={dataSource.fields}
                                defaultSortColumns={tableData.formatting_options.sort_fields}
                                showActionsCell={showActionsCell}
                            />
                        </TableHead>
                        <ReportTableBody
                            displayColumns={displayedColumns}
                            displayedHiddenColumnIds={enabledHiddenColumns}
                            isLoading={isLoading}
                            rows={tableContentsResponse?.rows || []}
                            dataSource={dataSource}
                            error={error}
                            selectedColumnFieldOptions={selectedColumnFieldOptions}
                            onRankingsClick={onRankingsClick}
                            onRowChecked={onRowSelect}
                            isRowChecked={(row) => selectedUrls.indexOf(getUrlFromRow(row)) !== -1}
                            table={tableData}
                            showActionsCell={showActionsCell}
                        />
                    </Table>)}
            </div>
            <TableContainer className={classes.root}>
                <Table stickyHeader className={classes.root} component={"div"}>
                </Table>
            </TableContainer>
            <Grid container>
                <Grid container item xs={12} justifyContent="flex-end" alignItems={"center"}>
                    {tableData.data_collection_date ? (
                        <Tooltip title={getCollectionDate(tableData.data_collection_date)}>
                            <IconButton className={classes.tableInfoIcon}>
                                <InfoOutlined className={"valign-icon"}/>
                            </IconButton>
                        </Tooltip>) : null}
                    {tableData.export_allowed ?
                        <IconButton onClick={() => setIsExportDialogOpen(true)} disabled={tableData.disabled}>
                            <FileDownloadIcon/>
                        </IconButton>
                        : null}
                    <TablePagination
                        count={lastKnownRowCount}
                        onPageChange={(event, page) => setPageOptions({
                            ...pageOptions,
                            page
                        })}
                        page={pageOptions.page}
                        rowsPerPage={pageOptions.perPage}
                        onRowsPerPageChange={(event) => setPageOptions({
                            ...pageOptions,
                            perPage: parseInt(event.target.value)
                        })}
                        component={"div"}
                    />
                </Grid>
            </Grid>
            <ExportTableDialog
                useTableExport={useTableExport}
                onClose={() => setIsExportDialogOpen(false)}
                open={isExportDialogOpen}
            />
            {isSearchConsoleEnabled(tableData) && isSearchConsoleConfigured(report) ?
                <PostDetailsPanel
                    open={rankingsPanelOpen}
                    onClose={() => setRankingsPanelOpen(false)}
                    page={rankingPanelPage}
                    searchConsoleDayRanges={tableData
                        .search_console_settings.day_range
                        .split(",")
                        .map(dayStr => parseInt(dayStr))
                    }
                    searchConsole={searchConsoleEnabled}
                /> : null}
        </Paper>
    </ReportTableContext.Provider>
}

export default ReportTable;